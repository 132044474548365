import React from 'react'
import { Container } from 'react-bootstrap'
import './App.scss'
import AppContext from './context/AppContext'
import TopNav from './components/TopNavBar/TopNav'
import Footer from './components/Footer/Footer'
import { withRouter } from 'react-router-dom'
import MainContent from './components/MainContent'
import { isLoggedIn } from './utils'
import getSorElements from './common/use-cases/get-sor-elements'
import 'react-datepicker/dist/react-datepicker.css'

const currentYear = new Date().getFullYear()
const availableYears = [currentYear - 3, currentYear - 2, currentYear - 1, currentYear, currentYear + 1]

const App = ({ repoFactory, location, accountMappingLimit }) => {
  const [sorElements, setSorElements] = React.useState(null)
  const [lastRunTime, setLastRunTime] = React.useState(null)
  const [selectedYear, setSelectedYear] = React.useState(currentYear)

  React.useEffect(() => {
    if (isLoggedIn()) {
      getSorElements(selectedYear, {
        commonRepo: repoFactory.commonRepo(),
        observer: {
          elementsReceived: (elements) => {
            setSorElements(elements)
          },
          errorGettingData: () => {
            setSorElements(null)
          },
          receiveLastRunTime: (time) => {
            setLastRunTime(time)
          }
        }
      })
    }
  }, [repoFactory, setSorElements, selectedYear, setLastRunTime, localStorage.getItem('expirationTime')])

  return (
    <AppContext.Provider
      value={{
        repoFactory,
        sorElements,
        selectedYear,
        setSelectedYear,
        availableYears,
        lastRunTime,
        accountMappingLimit
      }}
    >
      <TopNav isAuthenticated={isLoggedIn()} lastRunTime={lastRunTime} />
      <div className="App">
        <MainContent location={location} repoFactory={repoFactory} isAuthenticated={isLoggedIn()} />
      </div>
      <Footer />
    </AppContext.Provider>
  )
}

export default withRouter(App)
