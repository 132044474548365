import { BudgetStatusLabel } from 'Budgets/constants/BudgetStatus'
import { getTotalBudgetAndSpend } from 'Budgets/helpers/functions'
import SemiCircularProgressbar from 'components/common/SemiCircularProgressbar/SemiCircularProgressbar'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const currentMonth = new Date().getMonth()

function ProductCard({ portfolioName, product, isMonthly, selectedProduct, setSelectedProduct }) {
  const { percentage, totalBudget, totalSpend } = getTotalBudgetAndSpend([product], isMonthly)

  const lastMonthData = getTotalBudgetAndSpend([product], true, currentMonth - 1)
  const variance = totalSpend - totalBudget
  const isSelected = selectedProduct?.id === product.id

  return (
    <Card className={`budget ${isSelected ? 'selected-card' : ''} ${product.status} mb-3`}>
      <Card.Body>
        <div className={`status-label ${product.status}`}>{BudgetStatusLabel[product.status]}</div>
        <Row className="pt-2">
          <Col xs={12} className="mb-3">
            <div className="d-flex gap-2">
              <span className="header">Portfolio</span>

              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{portfolioName}</Tooltip>}>
                <span className="details text-ellipsis">{portfolioName}</span>
              </OverlayTrigger>
            </div>
            <div className="d-flex gap-2">
              <span className="header">Product</span>

              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{product.element4}</Tooltip>}>
                <span className="details text-ellipsis">{product.element4}</span>
              </OverlayTrigger>
            </div>
          </Col>
          {product.budgets ? (
            <>
              <div xs={12} className="d-flex justify-content-center">
                <SemiCircularProgressbar radio="80" percent={percentage} circledContent />
              </div>
              <div className="usage-in-chart">{dollarPriceFormatter(totalSpend)}</div>

              <div className="budget-info d-flex justify-content-between">
                <div className="divisions">
                  <div>
                    <span className="header">{isMonthly ? `Planned Spend` : `Available Budget`}</span>
                    <br />
                    <span className="details">
                      {isMonthly ? dollarPriceFormatter(totalBudget) : dollarPriceFormatter(totalBudget - totalSpend)}
                    </span>
                  </div>
                </div>
                <div className="divisions">
                  <div className="w-100 text-end">
                    <span className="header">{isMonthly ? `Spend Last Month` : 'Variance'}</span>
                    <br />
                    <span className="details">
                      {isMonthly ? dollarPriceFormatter(lastMonthData.totalSpend) : dollarPriceFormatter(variance)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Button
                  disabled={isSelected}
                  variant={isSelected ? 'secondary' : 'primary'}
                  onClick={() => setSelectedProduct(product)}
                >
                  Details
                </Button>
              </div>
            </>
          ) : (
            <div className="alert alert-info">No budgets yet</div>
          )}
        </Row>
      </Card.Body>
    </Card>
  )
}
export default ProductCard
